@import "../theme.scss";

.Image-title {
  font-family: $font-baby-1;
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 24px;
}

.Details-title {
  font-size: 40px;
}

.Card {
  box-shadow: 5px 5px $light-gray;
  text-decoration: none;
  color: black;
  text-align: center;
  transition: .2s transform, .2s box-shadow;
}

.Card:hover {
  transform: scale(1.05);
  box-shadow: 5px 10px 10px $light-gray;
}

h2 {
  margin-top: 30px;
}

.H2-info {
  margin-top: 30px;
  margin-bottom: 70px;
}

.Card-details {
  border-radius: 50px 15px;
  background: #fff;
  box-shadow: 0 0px 10px rgba(0, 0, 0, .08);
}