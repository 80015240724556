@import "./theme.scss";

.App {
  text-align: center;
  font-family: $font-baby-2;
}

.App-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.Content-wrapper {
  flex: 1;
}

.App-logo {
  pointer-events: none;
}

a {
  color: $yelloy-dark;
}

a:hover {
  color: $yellow;
}

a.Disabled {
  pointer-events: none;
  cursor: not-allowed;
  background-color: $gray;
}

.Button-container {
  margin-top: 60px;
  margin-bottom: 30px;
}

.Btn {
  font-family: $font-baby-1;
  font-size: 18px;
  fill: rgb(0, 0, 0);
  color: rgb(0, 0, 0);
  background-color: $yellow;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(0, 0, 0);
  border-radius: 50px;
  padding: 20px 40px;
  margin-top: 30px;
  text-decoration: none;

  &:not(:disabled) {
    cursor: pointer;
  }
}

.Btn:hover {
  background-color: $yelloy-dark;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(0, 0, 0);
  color: rgb(0, 0, 0);
  cursor: pointer;
}